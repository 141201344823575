@charset "UTF-8";
@font-face {
  font-family: 'SFPro';
  src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');

:root {
  --size: max(7px, 1vmin);
  --height: 98em;
  --pad: 1.25em;
  --border-radius: 6.666em;
  --gutter: calc(var(--pad) * 2);
  --scene-pad: 5vmin;
  --bg-blur: 0.333em;
  --button-width: 0.333em;
  --notch-height: 3.33em;
  --notch-width: 33.3%;
  --notch-radius: calc(var(--border-radius) - calc(var(--pad) * 2));
  --notch-duration: 0.333s;
  --ease: cubic-bezier(0.666, 0, 0.4, 1);
  --ease-spring: cubic-bezier(0.666, 0, 0.4, 1.2);
  --ease-out: cubic-bezier(0.15, 0, 0.333, 1);
  --border-width: 0.4em;
  --deep-purple: 284;
  --gold: 22.5;
  --space-black: 215;
  --silver: 254;
  --c-h: var(--deep-purple);
  --c-s: 100%;
  --c-l: 50%;
}

@-webkit-keyframes appear {
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes appear {
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
body {
  background-color: #ff3cac;
  background-image: linear-gradient(225deg, #9f065d 0%, #784ba0 50%, #16364e 100%);
  margin: auto;
}

.scene {
  display: flex;
  flex-wrap: wrap;
  gap: 3em 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: 100svh;
  font-family: SFpro, sans-serif;
  font-size: var(--size);
  padding: 0;
  box-sizing: border-box;
}

.phone-con {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}

.phone {
  position: relative;
  z-index: 1;
  aspect-ratio: 37/76;
  background: black;
  height: var(--height);
  border-radius: var(--border-radius);
  box-shadow: 0 0 0.1em 0.25em hsl(var(--c-h), 20%, 25%), 0 0 0 var(--border-width) hsl(var(--c-h), 30%, 85%);
  box-sizing: border-box;
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1);
  -webkit-animation: appear 1s var(--ease-out) forwards;
  animation: appear 1s var(--ease-out) forwards;
  backface-visibility: hidden;
}
.phone:before {
  content: '';
  position: absolute;
  top: var(--border-radius);
  right: calc(var(--border-width) * -1);
  bottom: var(--border-radius);
  left: calc(var(--border-width) * -1);
  border: 0.5em solid hsl(var(--c-h), 20%, 30%);
  border-left-width: 0;
  border-right-width: 0;
}

.buttons {
  position: absolute;
  inset: calc(var(--border-width) * -1);
  pointer-events: none;
}
.buttons .left,
.buttons .right {
  position: absolute;
  width: var(--button-width);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5em;
}
.buttons .left {
  right: 100%;
  top: calc(var(--border-radius) * 2);
}
.buttons .left .button:nth-child(1) {
  height: 3em;
  margin-bottom: 0.5em;
}
.buttons .right {
  left: 100%;
  transform: scale3d(-1, 1, 1);
  top: calc(var(--border-radius) * 3);
}
.buttons .right .button {
  height: 9.5em;
}
.buttons .button {
  background: hsl(var(--c-h), 20%, 95%);
  height: 6em;
  box-shadow: inset -0.15em 0 0.1em black, inset 0 0 0.1em hsl(var(--c-h), 30%, 90%),
    inset 0 0.2em 0.1em hsl(var(--c-h), 30%, 90%), inset 0 -0.2em 0.1em hsl(var(--c-h), 30%, 90%),
    inset -0.1em 0.333em 0.1em rgba(0, 0, 0, 0.5), inset -0.1em -0.333em 0.1em rgba(0, 0, 0, 0.5);
  border-top-left-radius: 0.2em;
  border-bottom-left-radius: 0.2em;
}

.screen-container {
  position: absolute;
  inset: 0;
  border-radius: var(--border-radius);
  border: var(--pad) solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--pad) * 2);
}

.bg {
  position: absolute;
  inset: 0;
  /* background-color: #FF3CAC; */
  /* background-image: linear-gradient(225deg, #9f065d 0%, #784BA0 50%, #16364e 100%); */
  background-image: url('./assets/images/Midjour.webp');
  background-size: cover;
  background-position: center;
  border-radius: calc(var(--border-radius) - var(--pad));
  overflow: hidden;
  transform: translateZ(0);
}
.bg > * {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 1s var(--ease-out) 0.5s;
  background: black;
}
.bg .section {
  --g-h: var(--c-h);
  --g-s: var(--c-s);
  --g-l: var(--c-l);
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border-radius: calc(var(--border-radius) - var(--pad));
  border-bottom-left-radius: 20em;
  border-bottom-right-radius: 20em;
}
.bg .section:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: radial-gradient(
      120% 110% at 50% 92.5%,
      hsla(0deg, 0%, 0%, 1) 33.3%,
      hsla(0deg, 0%, 0%, 0.738) 45.973%,
      hsla(0deg, 0%, 0%, 0.541) 55.978%,
      hsla(0deg, 0%, 0%, 0.382) 64.649%,
      hsla(0deg, 0%, 0%, 0.278) 70.9855%,
      hsla(0deg, 0%, 0%, 0.194) 76.655%,
      hsla(0deg, 0%, 0%, 0.126) 81.991%,
      hsla(0deg, 0%, 0%, 0.075) 86.7934%,
      hsla(0deg, 0%, 0%, 0.042) 90.7287%,
      hsla(0deg, 0%, 0%, 0.021) 93.997%,
      hsla(0deg, 0%, 0%, 0.008) 96.7984%,
      hsla(0deg, 0%, 0%, 0.002) 98.7994%,
      hsla(0deg, 0%, 0%, 0) 100%
    ),
    radial-gradient(
      100% 66.6% at 110% var(--g-hue-adjust-2-y, 100%),
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 1) 33.3%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.738) 45.973%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.541) 55.978%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.382) 64.649%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.278) 70.9855%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.194) 76.655%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.126) 81.991%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.075) 86.7934%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.042) 90.7287%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.021) 93.997%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.008) 96.7984%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.002) 98.7994%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0) 100%
    ),
    radial-gradient(
      100% 66.6% at -10% var(--g-hue-adjust-2-y, 100%),
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 1) 33.3%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.738) 45.973%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.541) 55.978%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.382) 64.649%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.278) 70.9855%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.194) 76.655%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.126) 81.991%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.075) 86.7934%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.042) 90.7287%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.021) 93.997%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.008) 96.7984%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0.002) 98.7994%,
      hsla(calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust))), 100%, 50%, 0) 100%
    ),
    radial-gradient(
      150% 100% at 50% 80%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0) 35%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.262) 47.35%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.459) 57.1%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.618) 65.55%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.722) 71.725%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.806) 77.25%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.874) 82.45%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.925) 87.13%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.958) 90.965%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.979) 94.15%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.992) 96.88%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 0.998) 98.83%,
      hsla(calc(var(--g-h) + 33.3), 100%, var(--g-lightness, 82.5%), 1) 100%
    );
  background-color: hsl(var(--g-h), var(--g-s), var(--g-l));
  transform: scale3d(1.1, 1.25, 1);
  transform-origin: bottom;
  transition: transform 1s var(--ease-out) 0.5s;
}
.bg .section:after {
  content: '';
  position: absolute;
  inset: 0;
  border: var(--border-width) solid rgba(255, 255, 255, 0.8);
  border-radius: inherit;
  filter: blur(0.05em);
  -webkit-mask-image: radial-gradient(100% 100% at 50% 70%, black 30%, transparent 50%);
  mask-image: radial-gradient(100% 100% at 50% 70%, black 30%, transparent 50%);
  transform: translatez(2px);
}
.bg .section .glow {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  mix-blend-mode: overlay;
  z-index: 1;
  background: radial-gradient(80% 150% at 50% 100%, hsl(var(--g-h), 100%, var(--g-l)), transparent 70%);
}
.bg .section:last-of-type {
  --g-h: calc(var(--c-h) - var(--g-hue-adjust, 0));
  --g-l: calc(var(--c-l) + 40%);
  --g-lightness: 95%;
  transform: scale3d(1, -1, 1) translateZ(1px);
}

.notch-container {
  position: absolute;
  z-index: 3;
  top: var(--pad);
  right: var(--pad);
  left: var(--pad);
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: calc(var(--notch-radius) * 2);
  pointer-events: none;
  outline: none;
  transition: var(--notch-duration) var(--ease);
  transition-property: max-height, max-width, filter, transform;
  will-change: max-width, max-height, filter;
}
.is-resizing .notch-container,
.is-resizing .notch-container * {
  transition: none;
}
.notch-container:hover,
.notch-container:focus-within {
  --shadow-opacity: 0.5;
  transition-timing-function: var(--ease-spring);
}
.notch-container:hover .content,
.notch-container:focus-within .content {
  --content-padding: 2em;
}
.notch-container:hover .content .text,
.notch-container:focus-within .content .text {
  opacity: 1;
}
.notch-container:hover .notch,
.notch-container:focus-within .notch {
  max-width: 100%;
  max-height: 100%;
  pointer-events: all;
  transform: scale3d(1, 1, 1);
}
.notch-container:hover ~ .notch-blur,
.notch-container:focus-within ~ .notch-blur {
  opacity: 1;
  max-height: calc(var(--notch-radius) * 3.333 + var(--pad));
}
.notch-container:focus-within {
  max-height: calc(var(--notch-radius) * 3);
  --bar-height: 1em;
  --bar-opacity: 1;
}
.notch-container:focus-within .left,
.notch-container:focus-within .right {
  max-height: calc(100% - var(--bar-height, 0%) - var(--content-gap));
}
.notch-container:focus-within ~ .notch-blur {
  max-height: calc(var(--notch-radius) * 5);
  opacity: 1;
}

.notch-blur {
  position: absolute;
  z-index: 2;
  top: calc(var(--pad) - 3px);
  right: calc(var(--pad) - 3px);
  left: calc(var(--pad) - 3px);
  height: 100%;
  max-height: calc(var(--notch-radius) * 1.5);
  -webkit-backdrop-filter: blur(0.2em);
  backdrop-filter: blur(0.2em);
  -webkit-mask-image: linear-gradient(
    to bottom,
    hsla(0deg, 0%, 0%, 1) 60%,
    hsla(0deg, 0%, 0%, 0.738) 67.6%,
    hsla(0deg, 0%, 0%, 0.541) 73.6%,
    hsla(0deg, 0%, 0%, 0.382) 78.8%,
    hsla(0deg, 0%, 0%, 0.278) 82.6%,
    hsla(0deg, 0%, 0%, 0.194) 86%,
    hsla(0deg, 0%, 0%, 0.126) 89.2%,
    hsla(0deg, 0%, 0%, 0.075) 92.08%,
    hsla(0deg, 0%, 0%, 0.042) 94.44%,
    hsla(0deg, 0%, 0%, 0.021) 96.4%,
    hsla(0deg, 0%, 0%, 0.008) 98.08%,
    hsla(0deg, 0%, 0%, 0.002) 99.28%,
    hsla(0deg, 0%, 0%, 0) 100%
  );
  mask-image: linear-gradient(
    to bottom,
    hsla(0deg, 0%, 0%, 1) 60%,
    hsla(0deg, 0%, 0%, 0.738) 67.6%,
    hsla(0deg, 0%, 0%, 0.541) 73.6%,
    hsla(0deg, 0%, 0%, 0.382) 78.8%,
    hsla(0deg, 0%, 0%, 0.278) 82.6%,
    hsla(0deg, 0%, 0%, 0.194) 86%,
    hsla(0deg, 0%, 0%, 0.126) 89.2%,
    hsla(0deg, 0%, 0%, 0.075) 92.08%,
    hsla(0deg, 0%, 0%, 0.042) 94.44%,
    hsla(0deg, 0%, 0%, 0.021) 96.4%,
    hsla(0deg, 0%, 0%, 0.008) 98.08%,
    hsla(0deg, 0%, 0%, 0.002) 99.28%,
    hsla(0deg, 0%, 0%, 0) 100%
  );
  opacity: 0;
  border-radius: calc(var(--border-radius) - var(--pad));
  transition: var(--notch-duration) var(--ease);
  transition-property: max-height, max-width, opacity, transform;
  will-change: max-width, max-height;
}

.notch {
  position: relative;
  border-radius: var(--notch-radius);
  pointer-events: all;
  overflow: hidden;
  color: white;
  display: flex;
  cursor: pointer;
  width: 100%;
  transition: inherit;
  transition-property: inherit;
  will-change: inherit;
  filter: drop-shadow(0 1em 2em hsla(0 0% 0% / var(--shadow-opacity, 0)));
  transform: scale3d(0.375, 0.4, 1);
  transform-origin: top;
}
.notch:before {
  content: '';
  position: absolute;
  inset: 0;
  background: black;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1'><defs><filter id='round'><feGaussianBlur in='SourceGraphic' stdDeviation='5' result='blur' /><feColorMatrix in='blur' mode='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9' result='goo'/><feComposite in='SourceGraphic' in2='goo' operator='atop'/></filter></defs></svg>#round");
  border-radius: inherit;
}

.content {
  --content-padding: 1.75em;
  --duration-height: 0.5em;
  --content-gap: 1em;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;
  padding: var(--content-padding);
  gap: var(--content-gap);
  font-size: 125%;
  transition-property: padding;
  will-change: padding;
  position: relative;
}
.content .left,
.content .right {
  height: 100%;
  max-height: calc(100% - var(--bar-height, 0%));
  display: flex;
  align-items: center;
  gap: 1em;
}
.content,
.content .left,
.content .right,
.content .bar,
.content .text {
  transition: var(--notch-duration) var(--ease-out);
}
.content .left,
.content .right,
.content .bar {
  transition-property: max-height, opacity;
  will-change: max-height;
}
.content .left {
  flex-grow: 2;
}
.content .text {
  display: flex;
  flex-direction: column;
  gap: 0.333em;
  transition-property: opacity;
  opacity: var(--bar-opacity, 0);
}
.content .text:before {
  content: 'The Move';
  order: 1;
  text-transform: uppercase;
  text-align: left;
}
.content .text:after {
  order: 2;
  content: 'Space Rangers';
  opacity: 0.5;
}
.content .right {
  flex-grow: 1;
}
.content .tile {
  background: #1a1ab3;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 33.3%;
  position: relative;
}
.content .tile:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 55% 40%, #131386, #cd1385 75%, transparent) center/133.3% 133.3%;
  background-color: yellow;
  filter: blur(0.05em);
}
.content .bar {
  display: flex;
  align-items: center;
  gap: 1em;
  flex-basis: 100%;
  height: 100%;
  max-height: var(--bar-height, 0%);
  color: rgba(255, 255, 255, 0.5);
  opacity: var(--bar-opacity, 0);
}
.content .bar .duration {
  position: relative;
  height: var(--duration-height);
  background: rgba(255, 255, 255, 0.25);
  border-radius: calc(var(--duration-height) * 0.5);
  overflow: hidden;
  flex-grow: 1;
}
.content .bar .duration:before {
  content: '';
  height: 100%;
  background: white;
  width: 25%;
  position: absolute;
}
.content .bar:before {
  content: '1:20';
}
.content .bar:after {
  content: '-1:48';
}

.camera {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--notch-height);
  aspect-ratio: 1/1;
  border-radius: 50%;
  pointer-events: none;
  position: absolute;
  z-index: 4;
  top: calc(var(--pad) * 2);
  right: calc(50% - calc(var(--notch-width) * 0.5));
  margin-right: calc(var(--pad) * 0.333);
}
.camera:before {
  content: '';
  height: 33.3%;
  aspect-ratio: 1;
  border-radius: inherit;
  box-shadow: inset 0 0 0.25em #4c4da3;
  background: radial-gradient(#6667ac, transparent 50%) no-repeat 33.3% 10%/75% 50%,
    radial-gradient(#454680, transparent 50%) no-repeat 60% 85%/50% 50%;
  background-color: #080928;
}

.app {
  --col: 4;
  aspect-ratio: 1;
  border-radius: 20%;
  flex-basis: 20%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 2%;
  box-sizing: border-box;
  font-size: 1.5em;
  --scale: 1.5;
  --duration: 0.8s;
  transform: scale3d(var(--scale), var(--scale), 1);
  -webkit-animation: appear var(--duration) var(--ease-out) forwards;
  animation: appear var(--duration) var(--ease-out) forwards;
  margin-bottom: 15px;
  text-align: center;
}

.appOverlay {
  height: 90%;
  width: 90%;
  margin: 5%;
  background-size: contain;
  border-radius: 20%;
}

.widget {
  --col: 4;
  aspect-ratio: 1;
  border-radius: 20%;
  flex-basis: 15%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 2%;
  padding: 5%;
  box-sizing: border-box;
  font-size: 1.5em;
  --scale: 1.5;
  --duration: 0.8s;
  transform: scale3d(var(--scale), var(--scale), 1);
  -webkit-animation: appear var(--duration) var(--ease-out) forwards;
  animation: appear var(--duration) var(--ease-out) forwards;
  box-shadow: black 0px 0px 5px;
  margin-bottom: 10px;
  background: #1e1e1e;
  color: white;
  flex-basis: 40%;
  border-radius: 15%;
}
.app:hover {
  cursor: pointer;
}

/* .app:hover,
.widget:hover {
  box-shadow: black 0px 0px 10px;
} */

.app-icon {
  /* background: #1e1e1e; */
  border-radius: inherit;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app {
  --scale: 1.75;
  --duration: 1s;
}

.widget .city {
  text-align: left;
  font-size: 1em;
  font-weight: bold;
  color: cornflowerblue;
  user-select: none;
}

.widget .temp {
  text-align: left;
  font-size: 3em;
  user-select: none;
}

.widget .icon {
  text-align: right;
  padding-top: 10px;
  font-size: 3em;
  color: #ffffff80;
}

.widgetTime {
  justify-content: space-evenly;
}

.widgetTime .city {
  text-align: left;
  font-size: 1em;
  font-weight: bold;
  float: left;
  color: cornflowerblue;
  user-select: none;
}

.widget .time {
  float: right;
  text-align: right;
  font-size: 1em;
  user-select: none;
}

.app .label {
  color: white;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1.7em;
  font-size: 0.8em;
  user-select: none;
}

.app .icon {
  font-size: 3em;
  color: white;
  height: 100%;
  line-height: 2em;
}

.appLabel {
  color: white;
  position: absolute;
  width: 100%;
  bottom: -1.5em;
  font-size: 0.8em;
  user-select: none;
}

.screen {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  overflow: hidden;
  padding: var(--gutter);
  border-radius: calc(var(--border-radius) - var(--pad));
  transition: opacity 1s var(--ease-out) 0.25s;
  z-index: 0;
  height: 100%;
}

.homeScreen {
  padding-top: calc(var(--gutter) * 3);
  gap: var(--gutter);
}

.lockScreen {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.5);
  padding-top: calc(var(--gutter) * 1.5);
  flex-direction: column;
  z-index: 2;
  user-select: none;
}

.lockScreen .date {
  font-size: 1.5em;
  color: white;
  margin-top: 10%;
  width: 100%;
  text-align: center;
}

.lockScreen .time {
  font-size: 12em;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.topBar {
  height: 4em;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.topBar .left {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.5em;
  left: 2em;
  top: 0.7em;
  font-weight: 500;
  user-select: none;
}

.actionBar {
  content: '';
  position: absolute;
  z-index: 2;
  background: white;
  width: 36.6%;
  bottom: calc(var(--pad) * 0.75);
  height: calc(var(--pad) * 0.5);
  border-radius: calc(var(--pad) * 0.25);
  filter: drop-shadow(0 0.1em 0.25em rgba(0, 0, 0, 0.1));
  cursor: pointer;
}
