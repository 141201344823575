@keyframes smoothAnimation {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.linkedInApp {
  background: #1b1f23;
  height: 100%;
  width: 100%;
  border-radius: calc(var(--border-radius) - var(--pad));
  z-index: 1;
  color: white;
  padding-top: 6em;
  box-sizing: border-box;
  user-select: none;
  animation: smoothAnimation 0.5s;
}

.LI_link {
  background: #0a66c2;
  padding: 0.5em;
  font-size: 1.5em;
  text-align: center;
  color: white;
  display: block;
  width: auto;
}

.LI_banner {
  height: 10em;
  background: url('../../assets/images/LI_Logo/banner.webp');
  background-size: contain;
}

.LI_profilePicture {
  height: 10em;
  border-radius: 50%;
  position: absolute;
  top: 14em;
  border: #1b1f23 3px solid;
  left: 1em;
}

.LI_profileContent {
  margin-top: 3em;
  padding-left: 1em;
  font-size: 2em;
}

.LI_name {
  font-weight: 500;
  font-size: 1.5em;
}

.LI_title {
  font-weight: 200;
  font-size: 0.8em;
}

.LI_school {
  font-weight: 200;
  margin-top: 0.5em;
  color: #ffffff99;
  font-size: 0.7em;
}

.LI_location {
  font-weight: 200;
  font-size: 0.7em;
  color: #ffffff99;
}

.LI_connections {
  font-weight: 400;
  font-size: 0.7em;
  color: #71b7fb;
  margin-top: 0.5em;
}

.LI_separator {
  height: 0.5em;
  background: black;
  margin-top: 1em;
  width: 100%;
  position: relative;
  left: 0;
}

.LI_experiences,
.LI_education {
  margin-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
}

.LI_experience {
  display: flex;
  padding: 1em 0;
}

.LI_experience:not(:last-child) {
  border-bottom: 1px solid #3d3d3d;
}

.LI_experience img {
  margin-right: 1.5em;
  width: 4em;
  height: 4em;
}

.LI_experiencesTitle,
.LI_educationTitle {
  font-size: 2.5em;
  font-weight: 500;
}

.LI_experience_details {
  flex: 1;
  margin-top: 0.5em;
}

.LI_experience_title {
  font-size: 1.5em;
  font-weight: normal;
}

.LI_experience_company {
  font-size: 1.2em;
  font-weight: 200;
}

.LI_experience_duration {
  font-size: 1em;
  font-weight: 200;
  color: #ffffff99;
}

.LI_education img {
  margin-right: 1.5em;
  width: 4em;
  height: 4em;
}

.LI_educationItem {
  display: flex;
  padding: 1em 0;
}

.LI_educationItem:not(:last-child) {
  border-bottom: 1px solid #3d3d3d;
}

.LI_education_details {
  flex: 1;
  margin-top: 0.5em;
}
.LI_education_school {
  font-size: 1.5em;
  font-weight: normal;
}
.LI_education_title {
  font-size: 1.2em;
  font-weight: 200;
}

.LI_education_duration {
  font-size: 1em;
  font-weight: 200;
  color: #ffffff99;
}
