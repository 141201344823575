.slider-container {
  width: 100%;
  margin-top: auto;
  margin-bottom: 10px;
}

.sliderUnlock {
  width: 100%;
}

.unlock-text {
  text-align: center;
  font-size: 2em;
  transition: opacity 0.5s ease;
  color: white;
}

.unlocked {
  opacity: 0;
}

input[type='range'] {
  appearance: none;
  width: 90%;
  background: #ffffff08;
  margin: 10px 0;
  padding: 0px 5%;
  height: 3em;
  border-radius: 10px;
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  width: 25px;
  height: 25px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
