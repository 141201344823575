@keyframes smoothAnimation {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.messageApp {
  background: #1b1f23;
  height: 100%;
  width: 100%;
  border-radius: calc(var(--border-radius) - var(--pad));
  z-index: 1;
  color: white;
  box-sizing: border-box;
  user-select: none;
  animation: smoothAnimation 0.5s;
  padding-top: 6em;
  padding-left: 2em;
}

.MsgTopBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4em;
  color: cornflowerblue;
  font-size: 1.5em;
  padding-right: 1em;
}

.MsgTitle {
  font-size: 3em;
  font-weight: bold;
}

.MsgComponent {
  display: flex;
  flex-direction: row;
  padding: 1em 0;
}

.MsgComponent .MsgAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5em;
  width: 5em;
  border-radius: 50%;
}

.MsgComponent .MsgDetails {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

.MsgComponent .MsgDetails .MsgName {
  font-size: 1.5em;
  font-weight: bold;
}

.MsgComponent .MsgDetails .MsgMessage {
  font-size: 1em;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0.5em;
}

.MsgComponent .MsgDetails .MsgTime {
  font-size: 1em;
}
