/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 5em;
  height: 3em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 2.5em;
  width: 2.5em;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #21f352;
}

input:focus + .slider {
  box-shadow: 0 0 1px #21f352;
}

input:checked + .slider:before {
  -webkit-transform: translateX(2em);
  -ms-transform: translateX(2em);
  transform: translateX(2em);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
