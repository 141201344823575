@keyframes smoothAnimation {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.settingsApp {
  background: black;
  height: 100%;
  width: 100%;
  border-radius: calc(var(--border-radius) - var(--pad));
  z-index: 1;
  color: white;
  padding-top: 7em;
  padding: 7em 1em;
  box-sizing: border-box;
  user-select: none;
  animation: smoothAnimation 0.5s;
}

.settingsTitle {
  font-size: 3em;
  margin-bottom: 0.5em;
  font-weight: bold;
  text-align: left;
}

.settingsHeader {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  background: #111;
  padding: 1em;
  border-radius: 1em;
}

.settingsHeader:hover {
  background: #222;
}

.settingsHeader img {
  width: 6em;
  height: 6em;
  border-radius: 50%;
}

.settingsHeaderInfo {
  flex: 1 1;
  text-align: left;
  padding-left: 2em;
}

.settingsHeader h2 {
  font-size: 3em;
  margin: 0;
  font-weight: 200;
}

.settingsHeader p {
  margin: 0;
  font-weight: 100;
  font-size: 1.2em;
}

.settingsSection {
  margin-bottom: 3em;
}

.settingsItem:first-child {
  border-radius: 1em 1em 0 0;
}

.settingsItem:last-child {
  border-radius: 0 0 1em 1em;
  padding-bottom: 1em;
}

.settingsItem:last-child .settingsItemSeparator {
  display: none;
}

.settingsItem {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-top: 1em;
  background: #111;
  flex-wrap: wrap;
}

.settingsItem .switch {
  margin-left: auto;
  margin-right: 1em;
}

.settingsItem:hover {
  background: #222;
}

.settingsItemIcon {
  border-radius: 25%;
  background: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  margin-left: 0.5em;
  padding: 0.2em;
}

.settingsItemName {
  font-size: 1.5em;
  font-weight: 200;
  margin-left: 1em;
}

.settingsItemText {
  font-size: 1.5em;
  font-weight: 200;
  margin-left: 1em;
  color: grey;
  margin-left: auto;
  margin-right: 1em;
}

.settingsItemSeparator {
  height: 1px;
  background: #333;
  margin-top: 1em;
  width: 88%;
  margin-left: auto;
}

.settingsItemArrow {
  font-size: 1em;
  color: grey;
  margin-right: 1em;
  font-weight: bold;
}
