@keyframes smoothAnimation {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.phoneApp {
  display: flex;
  background: black;
  height: 100%;
  width: 100%;
  border-radius: calc(var(--border-radius) - var(--pad));
  z-index: 1;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: smoothAnimation 0.5s;
}

.numberInput {
  width: 80%;
  height: 3em;
  margin-bottom: 20px;
  font-size: 3em;
  text-align: center;
}

.dialer {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.dialerRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dialerButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  font-size: 3em;
  cursor: pointer;
  background-color: #333;
  user-select: none;
}

.dialerButton:hover {
  background-color: #444;
}

.dialerButton:active {
  background-color: #555;
}

.dialerButton div {
  font-size: 1.2em;
}

.dialerButton.hidden {
  background: none;
}

.dialerButton.callButton {
  background-color: #0f0;
}

.dialerButton div:nth-child(2) {
  font-size: 0.5em;
  letter-spacing: 0.2em;
  height: 1em;
}

.dialerButton.delete {
  color: #444;
  background: none;
}

.dialerButton.delete:hover {
  color: #555;
}
